
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































































































::v-deep {
  .hero__title {
    @include mq(l) {
      max-width: col(11, 14);
    }
  }
}
